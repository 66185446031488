
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Container,Col,
    Modal,
    ModalBody,
    ModalHeader,
    ModalTitle,
    ModalFooter,
     Row,Card,CardBody,CardTitle,FormControl,Form,FormGroup, InputGroup, CardHeader } from 'react-bootstrap';
import './pol.css'
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useState } from 'react';
import Kwith from './Kwith';
import Pay from './Kmdepo';
import Kdepo from './kdeposit';
import './pack.css'

export default function Kpack() {
    const timy = new Date()
    const limo = timy.toDateString()
    const [selectedComponent, setSelectedComponent] = useState('main1');

   return (
    <>
    {
        selectedComponent === "main1" && (
    


            <div class="wrapper"  >
            
            <div class="price-tab weekly active animate__animated animate__fadeIn" style={ {display:"block"}}>
                <div class="pricing-table">
                    <div class="pricing-box-2">
                        <h2>CORPORATE PLAN </h2>
                        <span class="price">40% - 45% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>minimum: $50,000</li>
                            <li>maximum: UNLIMITED</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>
                    <div class="pricing-box-2">
                        <h2>ULTIMATE PLAN </h2>
                        <span class="price">35% - 40% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>minimum: $20,000</li>
                            <li>maximum: $49,999</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>
                    <div class="pricing-box-2">
                        <h2>PREMIUIM PLAN </h2>
                        <span class="price">30% - 35% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>minimum: $10,000</li>
                            <li>maximum: $19,999</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        
        
        
            <div class="price-tab btc"  style={{diplay:"block", marginTop:"4rem"}}>
                <div class="pricing-table">
        
                    <div class="pricing-box-2">
                        <h2>MASTER PLAN</h2>
                        <span class="price">25% - 30% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>Minimum: $5,000</li>
                            <li>Maximum: $9,999</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>
        
                    <div class="pricing-box-2">
                        <h2>STANDARD PLAN</h2>
                        <span class="price">20% - 25% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>Minimum: $3,000</li>
                            <li>Maximum: $4,999</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>
                    <div class="pricing-box-2">
                        <h2>CORPORATE PLAN</h2>
                        <span class="price">90% - 95% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>Minimum: $10,000</li>
                            <li>Maximum: UNLIMITED</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>
                    <div class="pricing-box-2">
                        <h2>ULTIMATE PLAN</h2>
                        <span class="price">60% - 70% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>Minimum: $50,000</li>
                            <li>Maximum: $99,999</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>

                    <div class="pricing-box-2">
                        <h2>PREMIUIM PLAN</h2>
                        <span class="price">50% - 60% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>Minimum: $20,000</li>
                            <li>Maximum: $49,999</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>

                    <div class="pricing-box-2">
                        <h2>MASTERS PLAN</h2>
                        <span class="price">40% - 45% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>Minimum: $10,000</li>
                            <li>Maximum: $19,999</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>

                    <div class="pricing-box-2">
                        <h2>STANDARD PLAN</h2>
                        <span class="price">30% - 40% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>Minimum: 1,000</li>
                            <li>Maximum: $9,999</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>

                    <div class="pricing-box-2">
                        <h2>ULTIMATE PLAN</h2>
                        <span class="price">124% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>Minimum: $50,000</li>
                            <li>Maximum: $99,999</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>

                    <div class="pricing-box-2">
                        <h2>STARTER PLAN</h2>
                        <span class="price">150% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>Minimum: $100,000</li>
                            <li>Maximum: $149,999</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>

                    <div class="pricing-box-2">
                        <h2>PREMIUM PLAN</h2>
                        <span class="price">65% - 70% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>1 - 1.6 BTC</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>

                    <div class="pricing-box-2">
                        <h2>PRO CRYPTO</h2>
                        <span class="price">90% - 95% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>30+ BTC</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>

                    <div class="pricing-box-2">
                        <h2>STANDARD CRYPTO</h2>
                        <span class="price">70% - 75% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>4 - 14.9 BTC</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>

                    <div class="pricing-box-2">
                        <h2>PREMIUM CRYPTO</h2>
                        <span class="price">80% - 85% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>15 - 29.9 BTC</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>

                    <div class="pricing-box-2">
                        <h2>BASIC CRYPTO</h2>
                        <span class="price">65% - 70% PIPS</span>
                        
                        <span class="pricing-table-divider"></span>
                        <a class="btns" onClick={() => setSelectedComponent('main3')}><b>Get Started</b></a>
                        <span class="pricing-table-divider"></span>
                        <ul>
                            <li>1 - 1.5 BTC</li>
                            <li>10% Trade Commission</li>
                            <li>24/7 active support</li>
                        </ul>
                    </div>

                </div>
            </div>
        
        
        
           </div>
        
        


    // <Container fluid>
    // <div style={{ color:"white"}} className="page-title-box">
    // <h4>Trading Packages</h4>
    //     <div >
    //         <Button size='sm'  >
    //         &nbsp;{limo}
    //           <CalendarMonthIcon/>
    //         </Button>
    //     </div>
    // </div>

    //    <Row >
    //     <div style={{display:"flex", height:"50%"}}>
    //      <Col xs={6}>
    //                 <Card style={{ padding:"2px",width:"99.5%", height:"100%",margin:0, backgroundColor:"", borderRadius:"13px"}}>
    //                        <Card.Header as="h3" size="bg" style={{color:"whitesmoke",fontSize:"1rem"}}>
    //                           Starter
    //                        </Card.Header>
    //                          <hr style={{color:"white", marginBottom:0}} />
    //                         <Card.Body style={{backgroundColor:"#1b202e", borderRadius:"13px", borderTop: "2px solid white", width:"99%", margin:"-.1rem"}}>
    //                         <Card.Title style={{display:"flex",fontSize:"1rem", justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>ROI:</span>
    //                             <span className='text-success'>22%-34% returns</span>
    //                         </Card.Title> 
    //                         <hr  className='text-light' />
                            
    //                         <Card.Title style={{display:"flex",fontSize:"1rem", justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>Minimum:</span>
    //                             <span className='text-success'>$1000</span>
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                          <Card.Title style={{display:"flex",fontSize:"1rem", justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>Maximum:</span>
    //                             <span className='text-success'>$25,000</span>
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Card.Title style={{display:"flex",fontSize:"1rem", justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>5% trade commission</span>
                                
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Card.Title style={{display:"flex",fontSize:"1rem", justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>24/7 active support
    //                               </span>
                                
    //                         </Card.Title> 
    //                         <hr  className='text-light' /> 

    //                         <Button variant="success" size="lg" onClick={() => setSelectedComponent('main3')}
    //                       style={{width:"85%", marginBottom:"1.5rem", border:"2px dashed white", marginLeft:"2rem"}} >
    //                          Deposit</Button> 
    //                         </Card.Body> 
    //             </Card>
    //           </Col>

    //          <Col xs={6} md="6">
    //                 <Card style={{ padding:"2px",width:"99.5%", margin:0, height:"100%", backgroundColor:"black", border:"none", borderRadius:"13px"}}>
    //                        <Card.Header as="h3" size="bg" style={{color:"whitesmoke",fontSize:"1rem"}}>
    //                           Tier I
    //                        </Card.Header>
    //                          <hr style={{color:"white", marginBottom:0}} />
    //                          <Card.Body style={{backgroundColor:"#1b202e",  borderRadius:"13px",
    //                           borderTop: "2px solid white", width:"99%", margin:"-.1rem"}}>
    //                         <Card.Title style={{display:"flex",fontSize:"1rem", justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>ROI:</span>
    //                             <span className='text-success'>43%-48% returns</span>
    //                         </Card.Title> 
    //                         <hr  className='text-light' />
                            
    //                         <Card.Title style={{display:"flex",fontSize:"1rem", justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>Minimum:</span>
    //                             <span className='text-success'>$50,000</span>
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Card.Title style={{display:"flex",fontSize:"1rem", justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>Maximum:</span>
    //                             <span className='text-success'>$100,000</span>
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Card.Title style={{display:"flex",fontSize:"1rem", justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>5% trade commission</span>
                                
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Card.Title style={{display:"flex",fontSize:"1rem", justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>24/7 active support
    //                               </span>
                                
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Button variant="success" size="lg" onClick={() => setSelectedComponent('main3')}
    //                       style={{width:"85%", marginBottom:"1.5rem", border:"2px dashed white", marginLeft:"2rem"}} >
    //                          Deposit</Button> 
    //                         </Card.Body> 
    //             </Card>
    //          </Col>
    //          </div>




    //           <div style={{display:"flex", marginTop:"2rem"}}>
    //      <Col xs={6} md="6">
    //               <Card style={{ padding:"2px",width:"99.5%", height:"100%",margin:0, backgroundColor:"", borderRadius:"13px"}}>
    //                        <Card.Header as="h3" size="bg" style={{fontSize:"1rem", color:"whitesmoke"}}>
    //                           tier II
    //                        </Card.Header>
    //                          <hr style={{color:"white", marginBottom:0}} />
    //                          <Card.Body style={{backgroundColor:"#1b202e",fontSize:"1rem",   borderRadius:"13px",
    //                           borderTop: "2px solid white", width:"99%", margin:"-.1rem"}}>
    //                         <Card.Title style={{display:"flex",fontSize:"1rem",  justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>ROI:</span>
    //                             <span className='text-success'>81%-87% returns</span>
    //                         </Card.Title> 
    //                         <hr  className='text-light' />
                            
    //                         <Card.Title style={{display:"flex",fontSize:"1rem",  justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>Minimum:</span>
    //                             <span className='text-success'>150,000</span>
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Card.Title style={{display:"flex",fontSize:"1rem",  justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>Maximum:</span>
    //                             <span className='text-success'>$350,000</span>
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Card.Title style={{display:"flex",fontSize:"1rem",  justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>5% trade commission</span>
                                
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Card.Title style={{display:"flex",fontSize:"1rem",  justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>24/7 active support
    //                               </span>
                                
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Button variant="success" size="lg" onClick={() => setSelectedComponent('main3')}
    //                       style={{width:"85%", marginBottom:"1.5rem", border:"2px dashed white", marginLeft:"2rem"}} >
    //                          Deposit</Button> 
    //                         </Card.Body> 
    //             </Card>
    //          </Col>

    //          <Col xs={6} md="6">
    //               <Card style={{ padding:"2px",width:"99.5%", height:"100%",margin:0, backgroundColor:"", borderRadius:"13px"}}>
    //                        <Card.Header as="h3" size="bg" style={{fontSize:"1rem", color:"whitesmoke"}}>
    //                           Tier III
    //                        </Card.Header>
    //                          <hr style={{color:"white", marginBottom:0}} />
    //                          <Card.Body style={{backgroundColor:"#1b202e",  borderRadius:"13px",
    //                           borderTop: "2px solid white", width:"99%", margin:"-.1rem"}}>
    //                         <Card.Title style={{display:"flex",fontSize:"1rem",  justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>ROI:</span>
    //                             <span className='text-success'>186%-210% returns</span>
    //                         </Card.Title> 
    //                         <hr  className='text-light' />
                            
    //                         <Card.Title style={{display:"flex",fontSize:"1rem",  justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>Minimum:</span>
    //                             <span className='text-success'>$500,000
    //                         </span>
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Card.Title style={{display:"flex",fontSize:"1rem",  justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>Maximum:</span>
    //                             <span className='text-success'>Unlimited
    //                        </span>
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Card.Title style={{display:"flex",fontSize:"1rem",  justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>5% trade commission</span>
                                
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Card.Title style={{display:"flex",fontSize:"1rem",  justifyContent:"space-between"}}>
    //                             <span className='text-light' style={{marginRight:"7rem", textDecoration:"underline"}}>24/7 active support
    //                               </span>
                                
    //                         </Card.Title> 
    //                         <hr  className='text-light' />

    //                         <Button variant="success" size="lg"  onClick={() => setSelectedComponent('main3')} 
    //                       style={{width:"85%", marginBottom:"1.5rem", border:"2px dashed white", marginLeft:"2rem"}} >
    //                          Deposit</Button> 
    //                         </Card.Body> 
    //             </Card>
    //          </Col>
    //          </div> 

    //      </Row>
    //      </Container>
         
        )
    }

{
        selectedComponent === "main3" && (
            <Kdepo/>
        )
}

    </>
   )
}