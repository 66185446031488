import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from "../../assets/logo.png";
import Dropdown from 'react-bootstrap/Dropdown';
import SecurityIcon from '@mui/icons-material/Security';
import gif from "../../assets/trade-icc.gif"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LoginIcon from '@mui/icons-material/Login';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TradingViewWidget from "../../components/tradingViewWidget/TradingViewWidget";
import TapeTradingViewWidget from "../../components/tape/TapeTradingViewWidget";
import "./userdb.scss"
import "./bonce.css"
import { makeRequest } from "../../axios";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../authcontext/authContext";
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Heatmap from '../../components/heatmap/Heatmap';
import Kdepo from '../../components/userdbComp/kdeposit';
import Kwith from "../../components/userdbComp/Kwith";
import Kpack from '../../components/userdbComp/kpack';
import HistoryIcon from '@mui/icons-material/History';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import success from "../../assets/success.png"
import CancelIcon from '@mui/icons-material/Cancel';
import {Await, Link, useNavigate} from "react-router-dom"
import Form from 'react-bootstrap/Form';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import gold from "../../assets/goldR.png"
import silvar from "../../assets/silvaR.png"
import bronze from "../../assets/bronzeR.png"
import {  Container,Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalFooter,
   Row,Card,CardBody,CardTitle,FormControl,
   FormGroup, InputGroup,FormSelect,
    CardHeader, FormLabel } from 'react-bootstrap';
    import Apps from "../../components/drag and drop/drag"
    import React from "react";
    // import { FileUploader } from "react-drag-drop-files";
    import vidoo from "../../assets/live-trade.mp4"
    import backi from "../../assets/pexels-anna.jpg"
    import award from "../../assets/toppng.com-gold-badge.png"
    import silva from '../../assets/tier1.png'
    import silva1 from '../../assets/tier2.png'
    import silvas from '../../assets/silver.png'
    import silva2 from '../../assets/chamber.png'
    import silva3 from '../../assets/advance.png'
    import silva4 from '../../assets/ambassador.webp'
import TradingViewWidgett from '../tradingwidgets/tesla';
import TradingViewWidgetts from '../tradingwidgets/spx'; 
import TradingViewWidgetta from '../tradingwidgets/apple';   
import TradingViewWidgetty from '../tradingwidgets/alibaba';
import TradingViewWidgettb from '../tradingwidgets/ba'; 
import TradingViewWidgettm from '../tradingwidgets/samsung';
import { Helmet } from 'react-helmet-async'; 



const Dashboard = () => {
  const [selectedComponent, setSelectedComponent] = useState('main');
    const [open, setOpen] = useState(true);
    const { currentUser } = useContext(AuthContext);
    const [allstocks, setAllstocks] = useState([])
    const [userInfo, setUserInfo] = useState({});
    const [alltransactions, setAllTransactions] = useState([])
    const [noOftrans, setNoOftrans]= useState(5);
    const slice = alltransactions.slice(0, noOftrans)
    const [amountError, setAmountError] = useState('');
    const [selectedStock, setSelectedStock] = useState(null)
    const [assetHist, setAssetHist] = useState([])
    const [file, setFile] = useState(null);
    const [kycSuccess, setKycSuccess] = useState("")
    const [err, setErr] = useState(null);
    const navigate = useNavigate()
    const [lank, setLank] = useState([])
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const [kovs, setKovs] = useState(false);
    const [kovs1, setKovs1] = useState(false);
    const [kovs2, setKovs2] = useState(false);
    const [kovs3, setKovs3] = useState(false);
    // console.log(userInfo);
    const ranks = [
      {id: 6, img: silvas, rank: "Silver"},
      {id: 5, img: silva, rank: "tier 1 pro"},
      {id: 4, img: silva1, rank: "tier 2 gold"},
      {id: 1, img: silva2, rank: "chamber "},
      {id: 2, img: silva3, rank: "advanced chamber"},
      {id: 3, img: silva4, rank: "ambassador"},
      {id: 7, img: award, rank: "no rank yet"},

    ]
   
    const [hova, setHova] = useState(false)
    const [hova1, setHova1] = useState(false)
    const [hova2, setHova2] = useState(false)
    const [hova3, setHova3] = useState(false)
    const [hova4, setHova4] = useState(false)
    const [hova5, setHova5] = useState(false)

    const [dank, setDank] = useState(0)
    const [shows, setShows] = useState(false);
    const [userc, setUserc] = useState(false)
    const [stockdrop, setStockdrop] = useState(false)
    const [kycsub, setKycsub] = useState(true)

    const currentYear = new Date().getFullYear();

const [Kycf, setKycf] = useState({file:[],file2:[],
   tyid:"",
  city:"",
post:"",
 adress:"",
state:"",
idn:""})
const fileTypes = ["JPG", "PNG", "GIF"];
const hovs = "drop here"

  // const [filek, setFilek] = useState({});
  // const handleChangek = (file) => {
  //   setFilek((prev) => ({...prev,  pix:file, }));
  // };
  // const handleChangeks = (file2) => {
  //   setFilek((prev) => ({...prev,  pix3:file2}));
  // };



  const handleDrop = (event) => {
    event.preventDefault();

    const files = event.dataTransfer.files;

    if (files.length > 0) {
      readAndSetImages(files);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileInputChange = (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      readAndSetImages(files);
    }
  };

  const readAndSetImages = (files) => {
    const imagesArray = [];

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();

      reader.onload = () => {
        imagesArray.push(reader.result);

        // If all files are processed, set the state
        if (imagesArray.length === files.length) {
          setSelectedImages(imagesArray);
        }
      };

      reader.readAsDataURL(files[i]);
    }
  };


// second image upload


  const handleDropi = (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];

    if (file) {
      readAndSetImage(file);
    }
  };

  const handleDragOveri = (event) => {
    event.preventDefault();
  };

  const handleFileInputChangei = (event) => {
    const file = event.target.files[0];

    if (file) {
      readAndSetImage(file);
    }
  };

  const readAndSetImage = (file) => {
    const reader = new FileReader();


    reader.onload = () => {
      setSelectedImage(reader.result);
    };

    reader.readAsDataURL(file);
  };




    
    const handleBuyStock = (item) => {
      setSelectedStock(item);

    }
  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };

    const handlesubmit = async (e) => {
      e.preventDefault();
      try {
        await makeRequest.post("user-info/stock/buy", selectedStock);
        
      } catch (err) {
        setErr(err.response.data);
        console.log(err);
      }

      setTimeout(() => {
        setSelectedComponent("asset-history")
      }, 1500);

      setTimeout(() => {
        setSelectedComponent('main')
      }, 7000);

    }

    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const handleKyc = async (e) => {
      // e.preventDefault();
      try {
        await makeRequest.post("user-info/kyc", Kycf);
        setKycSuccess("Upload succesful your details are being processed")
        setKycsub(false)
        
      } catch (err) {
        setErr(err.response.data);
        console.log(err);
      }
      
      setSelectedComponent('main')
       alert("kyc has been submitted and is being proccessed")
    }


    const handlelogout = async () => {
      try {
        localStorage.removeItem("user")
        await makeRequest.post("auth/logout");
        navigate("/login")
      } catch (err) {
        setErr(err.response.data);
      }
    };

    // const handleChange = (e) => {
    //   const { name, value } = e.target;
    //   setSelectedStock((prev) => ({ ...prev, [name]: value }));

    //   if(name === "amount" && parseFloat(value) < currentUser.main_balance ){
    //     setAmountError("Balance not enough")
    //   }

      
    //   if (name === 'amount' && parseFloat(value) < 15000) {
    //     setAmountError('This transaction requires more than 15000 usd');
    //   } else {
    //     setAmountError('');
    //   }
    // };

     const Takingcare = (e)=>{

      const { name, value, file } = e.target;
    
      setKycf((prev) => ({ ...prev, file:selectedImages, file2:selectedImage, [name]: value }));
       

     }
    //  console.log(Kycf)
    
    const handleChange = (e) => {
      const { name, value } = e.target;
    
      setSelectedStock((prev) => ({ ...prev, [name]: value }));
      console.log(selectedStock)
    
      // Validate the amount
      if (name === 'amount') {
        if (parseFloat(value) < 15000) {
          setAmountError('This transaction requires more than 15000 USD');
        } else if (parseFloat(value) > userInfo.info?.main_balance) {
          setAmountError('Balance not enough');
        } else {
          setAmountError('');
        }
      }
    };

   
     const olpi = userInfo.info?.main_balance

    useEffect(() => {
      
  console.log(olpi, "her")
   if(olpi <= 4999){
    setDank(6)
   }

    if(olpi > 4999){
      setDank(0)
      console.log(dank, "dank")
      
    }
    
   else if (userInfo.info?.main_balance > 24900) {
    setDank(1)
    console.log(dank, "dank")
   } 
   if (olpi > 49900){
      setDank(2)
      console.log(dank,"kolp3")
  }
   if (olpi > 99999){
    setDank(3)
    console.log(dank,"kolp3")
  }
  if(olpi > 299999){
    setDank(4)
  }
  if(olpi > 499999){
    setDank(5)
  }
  
  }, [olpi]);
  






  useEffect(() => {
    makeRequest.get("user-info/user").then((res) => {
      setUserInfo(res.data)
      
    } 
    )

  }, []);

  useEffect(() => {
    makeRequest.get("user-info/allstocks").then((res) => {
      setAllstocks(res.data)
    } 
    )

  }, []);

  useEffect(() => {
    makeRequest.get("user-info/stocks").then((res) => {
      setAssetHist(res.data)
    } 
    )
  }, []);




  useEffect(() => {
      makeRequest.get("user-info/alltransactions").then((res) => {
        setAllTransactions(res.data)
      } 
      )
  }, []);


    return(
      <>
      {/* <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet> */}
       
        <div className="dashboard" >
         <div className="navba roww " >
            <div className="nav-col roww">
               <Button
               className="n-btn"
               onClick={() => setOpen(!open)}
               aria-controls="example-collapse-text"
               aria-expanded={open}
               >
               <MenuIcon />
              </Button>
            </div>

            <div className="nav-col roww">
                <select><option>ENG</option></select>
                
                <span   >{currentUser.name}</span>
                
                <AccountCircleIcon onClick={()=>{setUserc(!userc)}} /> 
                <Button style={userc ? {marginLeft:"1rem",backgroundColor:"white", width:"40%", borderRadius:"5px", padding:".4rem", marginTop:".3rem"} : {display:"none"}}>
                  <input onClick={handlelogout} style={{marginBottom:"5px", width:"100%"}} value="log out"/> <br/>
                  <Link to="/changepass">
                  <input style={{marginBottom:"5px", width:"100%"}} value="change password" />
                  </Link>
                </Button>
                
            </div>
      </div>
        <div style={{display: "flex"}} >
        <Collapse in={open} dimension="width">
          <div id="example-collapse-text">
            
            <div className="sidebar col"   style={{ width: '250px',background: "#010a12" , justifyContent: "space-between" }}>
              <div className="col" style={{gap: "20px"}} >
                <Link to="/">
               <img className="logo" src={logo} alt="" />
               </Link>
               <div className="d-board" >
                <button
                className='d-board-btn'
                 onClick={() => setSelectedComponent('main')} 
                >
                  <HomeIcon />  Dashboard</button>
              </div>
              <div>
                <div className="sidebar-others col " >
                  <div className="col users" >
                    <div className="roww" style={{gap: "7px" }} >
                    <Button variant="success"
                     onClick={() => setSelectedComponent('deposit')}
                    >Deposit</Button>{' '}
                    <Button variant="outline-secondary"
                     onClick={() => setSelectedComponent('withdraw')}
                    >Withdraw</Button>{' '}
                    </div>

                  </div>
                  

                  <div className="col req" >
                  <button
                  className='sb-btn'
                  onClick={() => setSelectedComponent('all-transactions')}
                  onMouseOver={()=>setKovs(true)}
                  onMouseOut={()=>setKovs(!kovs)}
                  >
                  <span style={ {fontWeight:"bolder",}} className="small" > <FormatListBulletedIcon /> All transactions</span>
                  </button>
                  <button
                  className='sb-btn'
                  onClick={() => setSelectedComponent('buy-stock')}
                  
                  >
                  <span style={ {fontWeight:"bolder"}}className="small" > <FormatListBulletedIcon /> Buy stock</span>
                  </button>

                  
                  <button
                  className='sb-btn'
                  onClick={() => setSelectedComponent('live trading')}
                  
                  >
                  <span style={{fontWeight:"bolder"}}className="small" > <FormatListBulletedIcon /> live trading</span>
                  </button>

                  <button
                  className='sb-btn'
                  onClick={()=>{setStockdrop(!stockdrop)}}
                  onMouseOver={()=>setKovs3(true)}
                  onMouseOut={()=>setKovs3(!kovs3)}
                  >
                  <div style={ {fontWeight:"bolder"}}className="small" > <FormatListBulletedIcon /> stock trading &nbsp; &nbsp; {!stockdrop ? <KeyboardArrowRightIcon/>: <KeyboardArrowDownIcon/> }</div>

                  </button>

                      <div style={stockdrop ? {marginLeft:"2.1rem", transitionDuration:"1s",
                       transitionTimingFunction:"ease-in-out" }:
                       {display:"none", marginLeft:"2.1rem"}}>
                        <p className='small' onClick={()=>{setSelectedComponent("TradingViewWidgett")}} style={{marginBottom:".8rem", cursor:"pointer"}}>TSLA</p>
                        <p className='small' onClick={()=>{setSelectedComponent("TradingViewWidgetts")}} style={{marginBottom:".8rem", cursor:"pointer"}}>SPX</p>
                        <p className='small' onClick={()=>{setSelectedComponent("TradingViewWidgetta")}} style={{marginBottom:".8rem", cursor:"pointer"}}>AAPL</p>
                        <p className='small' onClick={()=>{setSelectedComponent("TradingViewWidgetty")}} style={{marginBottom:".8rem", cursor:"pointer"}}>SPY</p>
                        <p className='small' onClick={()=>{setSelectedComponent("TradingViewWidgettb")}} style={{marginBottom:".8rem", cursor:"pointer"}}>BA</p>
                        <p className='small' onClick={()=>{setSelectedComponent("TradingViewWidgettm")}} style={{cursor:"pointer"}} >SAMSUNG</p>
                      </div>

                    <hr style={{color:"white", marginTop:"1rem"}}/>

                  <button
                  className='sb-btn'
                  onClick={() => setSelectedComponent('packages')}
                  
                  >
                    
                  <span style={{fontWeight:"bolder"}} className="small" > <FormatListBulletedIcon /> Trading Packages</span>
                  </button>
                  <button
                  className='sb-btn'
                  onClick={() => setSelectedComponent('asset-history')}
                  >
                  <span  style={{fontWeight:"bolder"}} className="small" > <HistoryIcon /> Asset history</span>
                  </button>
                  <button
                  className='sb-btn'
                  onClick={() => {setSelectedComponent('rank') }}
                  
                  >
                  <span  style={{fontWeight:"bolder"}}className="small" > <MilitaryTechIcon /> Ranking</span>
                  </button>


                
                  {/* <span className="small" ><AccountBalanceWalletIcon /> Add Wallet Address</span> */}
                  </div>
                  <div className="col req" >
                  <button
                  className='sb-btn'
                  onClick={() => setSelectedComponent('')}
                  >
                  <span style={{fontWeight:"bolder"}} className="small" > <HistoryIcon /> 2FA</span>
                  </button>
                  <button
                  className='sb-btn'
                  onClick={() => setSelectedComponent('kyc')}
                  >
                  <span style={{fontWeight:"bolder"}} className="small" > <HistoryIcon /> KYC Verification</span>
                  </button>


                  </div>
                  <div className="l-out" >
              <button
                  className='sb-btn'
                  onClick={handlelogout}
                  style={{background: "transparent", border: "none"}}
                  >
              <span style={{fontWeight:"bolder"}} className="small" > <LogoutIcon /> Log out</span>
              </button>
              </div>
                </div>
              </div>
              </div>

             


            </div>
          </div>
        </Collapse> 

        {
          selectedComponent === "main" && (
            <div className="main col" > 
            <div className="roww" style={{gap: "7px", alignItems: "center"}} >
              <AccountCircleIcon style={{color: "#35b94c"}} />
              <span style={{color: "#fff"}} >ID: {currentUser.id}</span>  
              <Button style={{padding: "2px"}} variant="warning"  >{!currentUser.kyc_status  ? "Not verified" : currentUser.kyc_status }</Button>{' '}
               
            </div>
            <span style={{marginTop:".5rem"}}><
              Button onClick={()=>{setSelectedComponent("rank")}}>RANK:</Button>
              {" "} <span style={{color:"white", fontSize:"1.3rem"}}>{ranks[dank].rank} </span>
             <img height="58px" src={ranks[dank].img} alt='' /></span>
              <TapeTradingViewWidget />
            <div className="roww kyc" >
              <SecurityIcon />
            <div className="col " >
              <span className="bold" >
              {!currentUser.kyc_status  ? "KYC verification required" : currentUser.kyc_status}
              </span>
              <span className="small" >
              {!currentUser.kyc_status  ? "Please submit the required KYC information to verify yourself. Click here to submit KYC information." : 
              ""}
                </span>
            </div>
            </div>
    
            <div className="b-div" >
              <div className="acc-div roww " >
                <div className="acc-f col" >
                <span className="bold" style={{color: "#fff"}} >Account Balance</span>
                <div className="bal-div" >
                  <span><FiberManualRecordIcon /> <span className="bold" >$ {userInfo.info?.main_balance} </span> </span>
                </div>
                <hr  />
                <span className="small" > {currentUser.email} </span>
                <button onClick={()=>{setSelectedComponent("all-transactions")}} className="v-btn" >View Transactions</button>
                </div>
                <div className="acc-sec col" >
                  <img className="gif"  src={gif} alt="" />
                </div>
              </div>
    
              <div className="ot-bal col" >
                <div className="col ot-bal-div">
                  <span className="bold" >Trading intrest</span>
                  <div className="bal-div">
                    <span><FiberManualRecordIcon /> $ {userInfo.info?.trading_balance} </span>
                  </div>
                  <button onClick={()=>{setSelectedComponent("asset-history")}} className="v-btn" style={{width: "40%"}} >View Trades</button>
                </div>
    
                <div className="col ot-bal-div"  >
                  <span className="bold" >Asset intrest</span>
                  <div className="bal-div" >
                    <span><FiberManualRecordIcon /> $ {userInfo.info?.asset_balance} </span>
                  </div>
                  <button onClick={()=>{setSelectedComponent("asset-history")}} className="v-btn" style={{width: "40%"}} >View Trades</button>
                </div>
    
              </div>
            </div>

            <div className='tr-head' style={{fontSize:"1.5rem", color:"white"}}>
              <span className='tr-span' >Stock View</span>
            </div>
            <div  style={{height: "500px"}} >
              <TradingViewWidget />
            </div>
    
            <div className=" roww rf-auth " >
            <div className="col ref" >
              <span className="h2s" >Refer and earn </span>
              <span className="bold" >
              Invite any active trader and earn
              instant 5% referral bonus
              </span>
              <span className="small" >Referal link:</span>
              <span className="small" >https://avtroncrest.com/register?ref=970071 <ContentCopyTwoToneIcon /> </span>
            </div>
    
            <div className="col auth" >
              <div className="roww at-row" >
                <span className="bold" > <CalendarMonthIcon style={{color: "blue"}} />  Joined at </span>
                <span className="small">{currentUser.created_at}</span>
              </div>
              <div className="roww at-row" >
                <span className="bold" > <LoginIcon style={{color: "green"}} />  Last login </span>
                <span className="small">n/a</span>
              </div>
              <div className="roww  at-row"  >
                <span className="bold" > <PrivacyTipIcon style={{color: "red"}} />  2 Factor Auth </span>
                <span style={{cursor: "pointer"}} className="small" >Off</span>
              </div>
    
    
            </div>
            </div>

            <>
            <meta name="viewport" content='width=1024' />
            <div id='' className='transactins'>
              <div className='tr-head' >
                <span className='tr-span' >Transctions</span>
              </div>
              {
              slice.map((item, i) => (
              <div className='roww tr-map' >
                <span className='tr-s-c' >{item?.type}</span>
                <span className='tr-s-c' style={{ color: item?.type === "DEPOSIT" ? "green" : "red" }} >
                {item?.trans_status === "APPROVED" ? "++" : ""}   { item?.amount}  </span>
                <span className='tr-s-c' >{item?.method_details}</span>
                <span className='tr-s-c' >{item?.trans_status}</span>
              </div>

                ))
              }
            </div>
            </>
            
            <div className='' style={{display: "flex", flexDirection: "column", gap: "10px", color: "#fff"}} >
              <span className="h2s" >Stock markets heatmap</span>
              <div style={{height: "400px"}} >
              <Heatmap />
              </div> 
            </div>    
          </div>    
          )
        }


      {selectedComponent == "TradingViewWidgett" && (
        
        <TradingViewWidgett/>
      
      )}
         
         {selectedComponent == "TradingViewWidgetts" && (
        
        <TradingViewWidgetts/>
      
      )}

        {selectedComponent == "TradingViewWidgetta" && (
        
        <TradingViewWidgetta/>
      
      )}

      {selectedComponent == "TradingViewWidgetty" && (
        
        <TradingViewWidgetty/>
      
      )}

      {selectedComponent == "TradingViewWidgettb" && (
        
        <TradingViewWidgettb/>
      
      )}
 
    {selectedComponent == "TradingViewWidgettm" && (
        
        <TradingViewWidgettm/>
      
      )}

{
          selectedComponent === "live trading" && (
            <div style={{width: "100%"}} >
             <Row>
              <Col style={{width:"100%", height:"100%"}} sm={12}>
                
                  <CardBody style={{width:"100%",padding:"30px" }}>
                    <video controls autoplay  style={{height:"30%", width:"100%"}}>
                    <source src={vidoo} type="video/mp4"></source>
            

                    </video>
                     <button class="bounce" onClick={()=>{setSelectedComponent("startlive")}}>
                   START LIVE TRADING
                      </button>
                  </CardBody>
              
              </Col>
             </Row>

               
                 

            </div>
          )
        }


          {
          selectedComponent === "startlive" && (
            <div style={{width: "100%"}} >
             <Row style={{height:"100%"}}>
              <Col style={{width:"100%", height:"100%"}} sm={8}>
                 <Card style={{width:"100%", height:"100%",opacity:"0.3",
                  backgroundImage: `url(${backi})`,backgroundSize: 'cover',  backgroundPosition: 'center center', backgroundRepeat:"none" }}>
                  <CardBody style={{width:"100%",padding:"30px", textAlign:"center",  color:"white", fontWeight:"600", marginTop:"20%"
                  , fontSize:"1.5em" }}>
                    <h1>
                  Hello!! the
                   interface is not yet open
                    on your account because you’ve
                     not reached the necessary requirements.
                     </h1>
                  </CardBody>
               </Card>
              </Col>
             </Row>
            </div>
            
          )
        }



        {
          selectedComponent === "deposit" && (
            <div style={{width: "100%"}} >
              <Kdepo />
              {/* <Kmdepo /> */}
            </div>
          )
        }
        {
          selectedComponent === "withdraw" && (
            <div style={{width:"100%"}}>
              <Kwith />
            </div>
          )
        }
        {
          selectedComponent === "all-transactions" && (
            <>
            <Card style={{width:"100%", height:"100%"}}>
              <div style={{width:"100%"}}>
              <div  style={{textAlign:"center"}}>
                <Button variant='success'> TRANSACTIONS</Button>
              </div>

              <div>
              {
              alltransactions.map((item, i) => (
              <div style={{display:"flex", justifyContent:"space-around", 
              borderBottom:"1px solid white", marginBottom:"1rem", padding:"1rem"}} >
                
                <span  >{item?.type}</span>
                <span  style={{ color: item?.type === "DEPOSIT" ? "green" : "red" }} >
                {item?.trans_status === "APPROVED" ? "++" : ""}   { item?.amount}  </span>
                <span  >{item?.method_details}</span>
                <span  >{item?.trans_status}</span>
                
              </div>

                ))
              }
            </div>
            </div>
            </Card>
            </>
          )
        }
        {
          selectedComponent === "packages" && (
            <Kpack />
          )
        }
        { selectedComponent === "buy-stock" && (
          <div className='buy-stock' >
            {
              allstocks.map((item, i) => (
                 <div className='card' >
                  <div className='logo-div' >
                  <span className='head' >{item.name}</span>
                  <img src={item.logo} alt='' />
                  <span className='symb' >{item.symbol}</span>
                  </div>
                  <div className='bottom-div'> 
                  <div className='roi' >
                    <span>ROI</span>
                    <span>+10% - 20%</span>
                  </div>
                  <button
                  onClick={() => {handleBuyStock(item); setSelectedComponent("purchase-form") }}  
                  >Buy</button>
                  </div>
                </div> 

              ))
            }
          </div>
        )
        }
                { selectedComponent === "asset-history" && (
          <div className='buy-stock' >
            {
              assetHist.map((item, i) => (
                 <div className='card' >
                  <div className='logo-div' >
                  <span className='head' >{item.name}</span>
                  <img src={item.logo} alt='' />
                    <span className='symb' style={{color: item.active === 1 ? "green" : "red" }} >{item.active === 1 ? "Active" : "Inactive"}</span>
                  </div>
                  <div className='bottom-div'> 
                  <div className='roi' >
                    <span>{item.status}</span>
                  <span  >$ {item.amount}</span>
                  </div>
                  <span style={{marginLeft: "10px"}} >{item.created_at}</span>
                  </div>
                </div> 

              ))
            }
          </div>
        )
        }

        {
          selectedComponent === "purchase-form" && (
            <div className='col' style={{justifyContent: "center", gap: "10px", alignItems: "center", width: "100%", height: "255px"}} >
              <form className='col' style={{width: "80%", padding: "15px", }} >
                <input className='inpt-purchase'   name='name' value={selectedStock?.name}   />
                <input style={{display: "none"}} value={selectedStock?.logo} onChange={handleChange}   />
                <input className='inpt-purchase'  name="amount" placeholder='Amount' onChange={handleChange} />
                <select className='inpt-purchase'  value={selectedStock?.compounding} onChange={handleChange}>
                  <option selected value={selectedStock?.Biweekly_compounding}  >Bi-weekly compounding</option>
                  <option value={selectedStock?.Monthly_compounding} >Monthly compounding</option>
                  <option value={ selectedStock?.months_compounding} >3 months compounding</option>
                  <option value={selectedStock?.sixMonths_compounding} >6 months  compounding</option>
                  <option value={selectedStock?.months_compounding} >9 compounding</option>
                  <option value={selectedStock?.months_compounding} >12 compounding</option>
                </select>
              </form>
              {amountError && <p style={{ color: 'red' }}>{amountError}</p>}
              <button className='btn-sub' onClick={handlesubmit} >Submit</button>
            </div>
          )
        }
        {
          selectedComponent === "success" && (
            <div className='scs-div' >
              <div className='scs-cnc' ><CancelIcon onClick={setSelectedComponent("main")} /></div>
               <img className='scs-img' src={success} alt='' />
               <span className='scs-span' >Successful!</span>
            </div>
          )
        }
        {
          selectedComponent === "kyc" && (
            <div className='col' style={{justifyContent: "center", padding: "20px 40px", alignItems: "center", width: "100%", gap: "15px"}}  >
            <div className='col'style={{gap: "14px", width:"105%"}} >
              <span className="h2s" >KYC Verification</span>
              <span style={{color: "#f2f4f9"}} >
              KYC (Know Your Customer) guidelines are a set of procedures that businesses and financial institutions 
              implement to verify the identity of their customers. These guidelines are crucial for preventing fraud, 
              money laundering, and other illegal activities. We accept the following documents for KYC verification
              </span>
              <ul style={{color: "#f2f4f9"}}>
                <li>Driver's License</li>
                <li>National ID</li>
                <li>Voter's card</li>
                <li>Other governmet recognized ID's</li>
              </ul>


               <Row style={{width:"100%", marginTop:"5rem"}}>
            <Col sm={12}>
                
                    <Form>
                    <Card style={{width:"100%", height:"100%"}}>
                       <CardBody style={{width:"100%"}}>
                      <CardTitle>
                      <h4>Upload Your Documents here</h4>
                      </CardTitle>
                     <Row>
                     <Col md={6}>
                      <FormGroup>
                      {/* <Form.Label>Front Page</Form.Label> <br/>
                      <FileUploader  handleChange={handleChangek} hoverTitle={hovs} 
                       name="file" multiple={true} types={fileTypes}  className="guZdik"/>  */}

    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      style={{
        border: '2px dashed #ccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        marginBottom:"1rem",
      }}
    >
      <h3>Drag and Drop Images Here</h3>
      <p>or</p>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
        id="fileInput"
        multiple
      />
      <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
        Choose files
      </label>

      {/* Display the selected images (if any) */}
      {selectedImages.length > 0 && (
        <div>
          <h2>Preview:</h2>
          {selectedImages.map((image, index) => (
            <img key={index} src={image} alt={`Selected ${index + 1}`} style={{ maxWidth: '100%', margin: '5px' }} />
          ))}
        </div>
      )}
    </div>
                      </FormGroup>

                     </Col>
                     <Col md={6}>

                     <FormGroup >
                      {/* <Form.Label>Back Page</Form.Label><br/>
                      <FileUploader onDrop={Takingcare} handleChange={handleChangeks} hoverTitle={hovs} 
                      name="file2" multiple={true} types={fileTypes}  className="guZdik"/>  */}

                    <div
                  onDrop={handleDropi}
               onDragOver={handleDragOveri}
                   style={{
                border: '2px dashed #ccc',
                borderRadius: '4px',
                padding: '20px',
                  textAlign: 'center',
              cursor: 'pointer',
              marginBottom:"1rem",
               }}
               >
               <h3>Drag and Drop Image Here</h3>
             <p>or</p>
                <input
        type="file"
        accept="image/*"
        onChange={handleFileInputChangei}
        style={{ display: 'none' }}
        id="fileInputs"
      />
      <label htmlFor="fileInputs" style={{ cursor: 'pointer' }}>
        Choose a file
      </label>
      </div>           
                      
                {selectedImage && (
                <div>
                   <h2>Preview:</h2>
                  <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%' }} />
                  </div>
                 )}
                      </FormGroup>

                     </Col>
                    
                     </Row>


                     <Row style={{marginTop:"4rem"}}>
                     <Col md={6} style={{marginTop:"1rem"}}>
                      <FormGroup>
                      <Form.Label>Type of ID</Form.Label> <br/>
                        <input name='tyid' type='text' onChange={Takingcare} style={{width:"100%",padding:"5px", borderRadius:"5px"}}/>
                      </FormGroup>

                     </Col>
                     <Col md={6} style={{marginTop:"1rem"}}>

                     <FormGroup >
                      <Form.Label>ID Number</Form.Label><br/>
                        <input name='idn' type='text' onChange={Takingcare}  style={{width:"100%",padding:"5px", borderRadius:"5px"}}/>
                      </FormGroup>

                     </Col>
                     
                     <Col md={6}  style={{marginTop:"1rem"}}>
                      <FormGroup>
                      <Form.Label>Address</Form.Label> <br/>
                        <input name='adress' type='text' onChange={Takingcare} style={{width:"100%",padding:"5px", borderRadius:"5px"}}/>
                      </FormGroup>

                     </Col>
                     <Col md={6 }style={{marginTop:"1rem"}}>

                     <FormGroup >
                      <Form.Label>City</Form.Label><br/>
                        <input name='city' type='text' onChange={Takingcare}  style={{width:"100%",padding:"5px", borderRadius:"5px"}}/>
                      </FormGroup>

                     </Col>


                     <Col md={6} style={{marginTop:"1rem"}}>
                      <FormGroup>
                      <Form.Label>Postal Code</Form.Label> <br/>
                        <input name='post' type='text' onChange={Takingcare} style={{width:"100%",padding:"5px", borderRadius:"5px"}}/>
                      </FormGroup>

                     </Col>
                     <Col md={6} style={{marginTop:"1rem"}}>

                     <FormGroup >
                      <Form.Label>State</Form.Label><br/>
                        <input name='state' type='text' onChange={Takingcare}  style={{width:"100%",padding:"5px", borderRadius:"5px"}}/>
                      </FormGroup>

                     </Col>

                     </Row>{kycsub ? 
                      <input type='submit' className='kydb'
                      style={{backgroundColor:"green", border:"none", padding:".5rem", marginTop:"1rem",
                       color:"white", borderRadius:"5px",}} onClick={()=>{setKycsub(false); handleKyc()}}/> : 
                       <Button style={{marginTop:"1rem"}}> Submitting ...</Button>}
                       </CardBody>
                    </Card>

                    </Form>
                
            </Col>
            
        </Row>

            </div>
               
           

              {/* <Form style={{color: "#f2f4f9"}}>
             <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>ID</Form.Label>
            <Form.Control type="file" placeholder="Enter email" />
            </Form.Group>
            </Form> */}



            {/* <button className='btn-sub' onClick={handleKyc} >Submit</button> */}

            </div>
          )
        }
        {
          selectedComponent === "rank" && (
            <div  style={{display:"flex", justifyContent:"center", width:"100%"}}>
            
                <Row style={{width:"100%", height:"100%"}}>
                  <Col xs={12} md={8} style={{width:"100%"}}>
                  <Card style={{width:"100%", height:"100%", border:"none", backgroundColor:"transparent"}}>
                    <CardBody className='cadbody' style={{display:"flex", justifyContent:"space-between", width:"100%", padding:"5.5rem", backgroundColor:"#262636"}}>
                      <div className='cad2'>
                         <img src={award}  style={{height:"13rem"}}/>
                         <img src={ranks[dank].img} style={{height:"7rem", marginLeft:"-8.6rem", marginBottom:"1.5rem"}}/>
                         <p style={{marginLeft:"3rem", fontSize:"1.5rem", marginTop:"1.5rem"}}>My Rank</p>
                      </div>
                      <div style={{margin:"4rem", textAlign:"center"}}>
                        <div>
                        <h4 style={{color:"gold"}}>$ {userInfo.info?.main_balance}.00</h4>
                        <p>My Deposit</p>
                        </div>
                        <div style={{marginTop:"1.7rem"}}>
                        <p>Bonus:</p>
                        <h4>$500</h4>
                        </div>
                      </div>
                    </CardBody>
                    <CardBody className='cadbody' style={{display:"flex", justifyContent:"space-between", width:"100%", padding:"5.5rem"}}>
                    <div style={{textAlign:"center"}}  >
                         <img src={award}  style={{height:"13rem"}} onMouseOver={()=>{setHova(!hova)}}  onMouseOut={()=>{setHova(false)}} />
                         <img src={silvas} style={{height:"7rem", marginLeft:"-11.6rem", marginBottom:"1.5rem"}}/>
                         <p style={{marginRight:"1rem", fontSize:"1.5rem", marginTop:"1.5rem"}}>Silver</p>

                         <div  style={hova ? {backgroundColor:"white", padding:"1rem", backgroundColor:"green", borderRadius:"10px",
                          marginTop:".9rem"} : {display:"none"}} >
                          <p>Minimum Deposit: $5,000.00</p>
                          <hr/>
                          <p> Bonus: $500.00</p>
                         </div>
                      </div>

                      <div className='cad3' style={{textAlign:"center"}}>
                         <img src={award}  style={{height:"13rem"}}  onMouseOver={()=>{setHova1(!hova)}}  onMouseOut={()=>{setHova1(false)}}/>
                         <img src={silva} style={{height:"7rem", marginLeft:"-11.6rem", marginBottom:"1.5rem"}}/>
                         <p style={{marginRight:"1rem", fontSize:"1.5rem", marginTop:"1.5rem"}}>Tier 1 Pro</p>
                         <div  style={hova1 ? {backgroundColor:"white", padding:"1rem", backgroundColor:"green", borderRadius:"10px",
                          marginTop:".9rem"} : {display:"none"}} >
                          <p>Minimum Deposit: $25,000.00</p>
                          <hr/>
                          <p> Bonus: $500.00</p>
                         </div>
                      </div>

                    </CardBody>

                    <CardBody className='cadbody' style={{display:"flex", justifyContent:"space-between", width:"100%", padding:"5.5rem"}}>
                    <div className='cad3' style={{textAlign:"center"}}>
                         <img src={award}  style={{height:"13rem"}} onMouseOver={()=>{setHova2(!hova)}}  onMouseOut={()=>{setHova2(false)}}/>
                         <img src={silva1} style={{height:"7rem", marginLeft:"-11.6rem", marginBottom:"1.5rem"}}/>
                         <p style={{marginRight:"1rem", fontSize:"1.5rem", marginTop:"1.5rem"}}>Tier 2 Gold</p>
                         <div  style={hova2 ? {backgroundColor:"white", padding:"1rem", backgroundColor:"green", borderRadius:"10px",
                          marginTop:".9rem"} : {display:"none"}} >
                          <p>Minimum Deposit: $50,000.00</p>
                          <hr/>
                          <p> Bonus: $500.00</p>
                         </div>
                      </div>

                      <div className='cad3' style={{textAlign:"center"}}>
                         <img src={award}  style={{height:"13rem"}} onMouseOver={()=>{setHova3(!hova)}}  onMouseOut={()=>{setHova3(false)}}/>
                         <img src={silva2} style={{height:"7rem", marginLeft:"-11.6rem", marginBottom:"1.5rem"}}/>
                         <p style={{marginRight:"1rem", fontSize:"1.5rem", marginTop:"1.5rem"}}>Chamber Level</p>
                         <div  style={hova3 ? {backgroundColor:"white", padding:"1rem", backgroundColor:"green", borderRadius:"10px",
                          marginTop:".9rem"} : {display:"none"}} >
                          <p>Minimum Deposit: $100,000.00</p>
                          <hr/>
                          <p> Bonus: $500.00</p>
                         </div>
                      </div>

                    </CardBody>

                    <CardBody className='cadbody' style={{display:"flex", justifyContent:"space-between", width:"100%", padding:"5.5rem", marginBottom:"1rem"}}>
                    <div className='cad3' style={{textAlign:"center", marginLeft:"-6rem"}}>
                         <img src={award}  style={{height:"13rem"}} onMouseOver={()=>{setHova4(!hova)}}  onMouseOut={()=>{setHova4(false)}}/>
                         <img src={silva3} style={{height:"7rem", marginLeft:"-8.8rem", marginBottom:"1.5rem"}}/>
                         <p style={{ fontSize:"1.5rem", marginTop:"1.5rem"}}>Advanced Chamber Level</p>
                         <div  style={hova4 ? {backgroundColor:"white", padding:"1rem", backgroundColor:"green", borderRadius:"10px",
                          marginTop:".9rem"} : {display:"none"}} >
                          <p>Minimum Deposit: $300,000.00</p>
                          <hr/>
                          <p> Bonus: $500.00</p>
                         </div>
                      </div>

                      <div className='cad3' style={{textAlign:"center"}}>
                         <img src={award}  style={{height:"13rem"}} onMouseOver={()=>{setHova5(!hova)}}  onMouseOut={()=>{setHova5(false)}}/>
                         <img src={silva4} style={{height:"7rem", marginLeft:"-8.8rem", marginBottom:"1.5rem"}}/>
                         <p style={{ fontSize:"1.5rem", marginTop:"1.5rem", marginLeft:"1.7rem"}}>Ambassador</p>
                         <div  style={hova5 ? {backgroundColor:"white", padding:"1rem", backgroundColor:"green", borderRadius:"10px",
                          marginTop:".9rem"} : {display:"none"}} >
                          <p>Minimum Deposit: $500,000.00</p>
                          <hr/>
                          <p> Bonus: $500.00</p>
                         </div>
                      </div>
                    
                    </CardBody>
                     <hr style={{width:"100%", marginBottom:"1.5rem"}}/>
                    <p> © {currentYear} avtroncrest</p>
                  </Card>
                  </Col>
                </Row>
      
              
            </div>
          )
        }

{
          selectedComponent === "purchase-forms" && (
            <div className='col' style={{justifyContent: "center", gap: "10px", alignItems: "center", width: "100%", height: "255px"}} >
              <form className='col' style={{width: "80%", padding: "15px", }} >
                <input className='inpt-purchase'   name='name' value={selectedStock.rank} disabled  />
                <input style={{display: "none"}} value={selectedStock?.logo} onChange={handleChange}   />
                <input className='inpt-purchase'  name="amount" placeholder='Amount' onChange={handleChange} />
                <select className='inpt-purchase'  value={selectedStock?.compounding} onChange={handleChange}>
                  <option selected value={selectedStock?.Biweekly_compounding}  >Bi-weekly compounding</option>
                  <option value={selectedStock?.Monthly_compounding} >Monthly compounding</option>
                  <option value={ selectedStock?.months_compounding} >3 months compounding</option>
                  <option value={selectedStock?.sixMonths_compounding} >6 months  compounding</option>
                  <option value={selectedStock?.months_compounding} >9 compounding</option>
                  <option value={selectedStock?.months_compounding} >12 compounding</option>
                </select>
              </form>
              {amountError && <p style={{ color: 'red' }}>{amountError}</p>}
              <button className='btn-sub' onClick={handlesubmit} >Submit</button>
            </div>
          )
        }
      </div>
      </div>
      </>
    )
}

export default Dashboard





