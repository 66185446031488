import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png"

import { AuthContext } from "../../authcontext/authContext";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {  Container,Col,
    Modal,
    ModalBody,
    ModalHeader,
    ModalTitle,
    ModalFooter,
     Row,Card,CardBody,CardTitle,FormControl,
     FormGroup, InputGroup,FormSelect,
      CardHeader, FormLabel } from 'react-bootstrap';



export default function  Changepass(props){
    const [inputs, setInputs] = useState({
        pass1: "",
        pass2:""
        
      });


    const handleChange = (e) => {
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      };
    
      const navigate = useNavigate();
    
      const { passupdate } = useContext(AuthContext);


    const handleClick = async (e) => {
        e.preventDefault();
        try {
          await passupdate(inputs);
          navigate("/login")
        } catch (err) {
          
        }
      };
  
 const check = props.inputs
  console.log(check)
return (
    <>
 <Row style={{justifyContent:"center",}}>
    <Col Col sm={8}>
     <Card style={{padding:"5rem", marginTop:"10%", background: "linear-gradient(rgba(134 ,214 ,194, 0.77), rgba(13, 55, 13, 0.76))"}}>
        <CardBody style={{textAlign:"center", color:"white"}}>
            <img src={logo} style={{width:"170px", height:"44px", marginBottom:"1rem"}}/>
            <h4>
                input New password
         <br/>
             </h4>
                <input type="text" required 
               name="pass1"
               onChange={handleChange}
               
               style={{width:"80%", marginTop:"1rem", padding:"7px", borderRadius:"5px"}}/>
               
               <h4>confirm password</h4>
               <input type="text" required 
               name="pass2"
               onChange={handleChange}
               
               style={{width:"80%", marginTop:"1rem", padding:"7px", borderRadius:"5px"}}/>


              <input className="inps" type="submit" value="Reset" onClick={handleClick} />
        </CardBody>
     </Card>
    </Col>
 </Row>


    
    </>
)

}